.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 20000;
  color: white;
  overflow: hidden;
}

// Locks the body from scrolling when nav overlay is open
body.backdrop-open {
    overflow: hidden;
}

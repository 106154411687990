.showcase {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
    }

    .showcase-content {
        position: relative;
        max-width: 650px;
        padding: 80px 0;
        @media (min-width: 1100px) {
            padding: 100px 0;
        }

        .display-1 {
            font-size: 3rem;
            margin-bottom: .5rem;
            @media (min-width: 768px) {
                margin-bottom: .75rem;
            }
            @media (min-width: 1100px) {
                font-size: 4rem;
            }
        }
          
        .display-2 {
            font-size: 2.2rem;
            margin-bottom: 1rem;
            @media (min-width: 1100px) {
                font-size: 3rem;
            }
        }

        .showcase-paragraph {
            margin-bottom: 2rem;
            font-size: 1.1rem;
            color: white;
        }
        .showcase-paragraph-display {
            margin-bottom: 1.5rem;
            font-size: 1.35rem;
            color: white;
        }

        .showcase-link {
            display: inline-block;
        }
    }
}

/* @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rock+Salt&display=swap');

:root {
  --primary-color: #c0bdc1;
  --secondary-color: #cf922a;
  --danger-color: #ff4444;
  --warning-color: #cf922a;
  --success-color: #00c851;
  --info-color: #33b5e5;
  --dark-color: #2e2e2e;
  --light-color: #cdcdcd;
  /*--main-bg-color: #060606;*/
  --main-bg-color: #201c14;
  --showcase-bg-color: #161616;
  --text-light: #cf922a;
  --text-dark: #2e2e2e;
  --text-primary: #c28318;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: var(--main-bg-color);
  -webkit-font-smoothing: antialiased;
  color: var(--primary-color);
  font-family: 'Roboto Condensed', sans-serif;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--warning-color);
  z-index: 1;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

p {
  margin: 0.5rem 0;
}

img {
  width: 100%;
}

section {
  position: relative;
  padding: 0 20px;
  @media screen and (min-width: 1100px) {
      padding: 0;
  }
}

.container {
  max-width: 1100px;
  /* min-width: 350px; */
  margin: 0 auto;
}

.secondary-color {
  color: var(--secondary-color);
}

.text-light {
  color: var(--text-light);
}

.text-dark {
  color: var(--text-dark);
}

.text-slim {
  font-weight: 100;
}

.col-6 {
  width: 50%;
}

.display-1 {
  font-size: 4rem;
  color: #fff;
}

.display-2 {
  font-size: 3rem;
}

.display-3 {
  font-size: 2rem;
  color: #fff;
}

/* Paddings */
.pt-8p {
  padding-top: 8%;
}

.btn {
  color: var(--primary-color);
  border: var(--warning-color) solid 1px;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  font-weight: 600;
  font-size: 1.1rem;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: inset 120px 100px 250px #000000, inset -120px -100px 250px #000000;
  -webkit-transition: color 1s ease-out;
  -moz-transition: color 1s ease-out;
  -o-transition: color 1s ease-out;
  transition: color 1s ease-out;
  z-index: 3000;
}

.btn:hover {
  color: #f3f3f3;
}

.ig-upcoming-shows {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  margin: auto;
  height: 88vh;
  padding: 10px;
  width: 85%;
  padding-top: 50px;
}

.ig-upcoming-shows h1 {
  padding-bottom: 15px;
  font-size: 2rem;
}

.ig-upcoming-shows .ig {
  width: 50%;
  padding-right: 10px;
}

.ig-upcoming-shows .ig .grid {
  display: flex; /* establish flex container */
  flex-wrap: wrap; /* enable flex items to wrap */
  justify-content: space-around;
  padding-top: 20px;
}
.ig-upcoming-shows .ig .cell {
  flex: 0 0 32%; /* don't grow, don't shrink, width */
  height: 120px;
  margin-bottom: 5px;
  background-color: #999;
}
.ig-upcoming-shows .ig .cell:nth-child(3n) {
  background-color: #c3c3c3;
}

.ig-upcoming-shows .ig h1 {
  font-size: 2rem;
}

.ig-upcoming-shows .upcoming-shows {
  width: 50%;
}

.ig-upcoming-shows .upcoming-shows .upcoming-shows-container .upcoming-show {
  display: flex;
  padding: 20px 0 0 10px;
}

.ig-upcoming-shows
  .upcoming-shows
  .upcoming-shows-container
  .upcoming-show
  .us-date {
  width: 12%;
  height: 70px;
  -webkit-box-shadow: -1px 0px 11px -2px rgba(181, 181, 181, 0.5);
  -moz-box-shadow: -1px 0px 11px -2px rgba(181, 181, 181, 0.5);
  box-shadow: -1px 0px 11px -2px rgba(181, 181, 181, 0.5);
  /*display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;*/
  margin: 0;
  padding: 0;
}

.ig-upcoming-shows
  .upcoming-shows
  .upcoming-shows-container
  .upcoming-show
  .us-date
  .month {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0;
  padding-top: 8px;
  text-align: center;
  color: var(--text-dark);
}

.ig-upcoming-shows
  .upcoming-shows
  .upcoming-shows-container
  .upcoming-show
  .us-date
  .day {
  font-family: 'Rock Salt', cursive;
  margin: 0;
  margin-top: -10px;
  font-size: 1.3rem;
  text-align: center;
  color: var(--text-primary);
}

.ig-upcoming-shows
  .upcoming-shows
  .upcoming-shows-container
  .upcoming-show
  .us-venue {
  width: 70%;
  padding-left: 15px;
}

.ig-upcoming-shows .upcoming-shows .upcoming-shows-container .upcoming-show a {
  border: solid 1px var(--secondary-color);
  padding: 5px 15px;
  font-weight: bold;
  color: var(--dark-color);
  -webkit-transition: all 2s ease; /* Safari prior 6.1 */
  transition: all 0.5s ease;
}

.ig-upcoming-shows
  .upcoming-shows
  .upcoming-shows-container
  .upcoming-show
  a:hover {
  color: var(--text-light);
  border: 1px solid var(--text-dark);
}
.fan-wall:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.fan-wall {
    display: flex;
    justify-content: center;
    // background: url('/img/dark-texture-bg.jpg') no-repeat top center;
    /*background-size: cover;*/
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;

    @media (min-width: 768px) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.fan-wall .container {
    position: relative;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fan-wall .fan-comment p::before {
  content: open-quote;
  font-size: 4rem;
  font-family: serif;
  color: var(--secondary-color);
  padding-right: 5%;
}
.fan-wall .fan-comment p {
    display: flex;
    text-align: center;
    padding: 0 30px;
    quotes: '“' '”' '‘' '’';
    font-size: 1rem;
    font-style: italic;
    @media (min-width: 768px) {
        font-size: 1.3rem;
    }
}

.fan-wall .fan-comment p::after {
  content: close-quote;
  font-size: 4rem;
  font-family: serif;
  color: var(--secondary-color);
  margin: 0;
  padding-left: 5%;
}

.fan-wall .fan-name {
  text-align: center;
  margin-top: 25px;
}

.fan-wall img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
}

.venues {
  height: 300px;
  width: 85%;
  margin: auto;
  background: #fff;
  z-index: -9999;
}

footer {
    margin: auto;
    width: 100%;
    background: #292721;
    padding-top: 20px;
    padding-bottom: 20px;
    @media (min-width: 768px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

footer .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

footer h3,
h4,
h5 {
  padding-bottom: 10px;
}

footer ul {
  line-height: 1.5rem;
}

/* footer .site-map-footer li::before {
  font-family: FortAwesome;
  content: '\f054';
  font-size: 0.5rem;
  padding-right: 10px;
  color: var(--secondary-color);
} */

footer .site-map-footer li {
  list-style: none;
}

footer .about-footer {
  width: 25%;
}

footer .about-footer img {
  width: 200px;
}

footer .about-footer p {
  line-height: 1.3rem;
}

footer .about-footer .social {
  display: flex;
  padding-top: 20px;
  justify-content: space-around;
  font-size: 1.5rem;
}

footer .about-footer .social i {
  padding-right: 25px;
  color: var(--secondary-color);
}

footer .site-map-footer ul {
  padding-top: 15px;
}

/* footer .contact-info h5::before {
  font-family: FontAwesome;
  padding-right: 5px;
  font-size: 0.8rem;
} */

footer .contact-info .email {
  padding-top: 20px;
}

/* footer .contact-info .email::before {
  content: '\f0e0';
} */

footer .contact-info .phone {
  padding-top: 20px;
}

/* footer .contact-info .phone::before {
  content: '\f095';
} */

footer .subscribe {
  width: 22%;
}

footer .subscribe a {
  color: #fff;
}

footer .subscribe p {
  padding-top: 10px;
  font-size: 0.9rem;
}

footer .subscribe .subscribe-form {
  padding-top: 15px;
  display: flex;
}

footer .subscribe .subscribe-form input[type='text'] {
  padding: 10px;
  width: 85%;
  margin: 0;
  border: none;
  font-size: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

footer .subscribe .subscribe-form input:focus,
footer .subscribe .subscribe-form button {
  outline: none;
}

footer .subscribe .subscribe-form button {
  padding: 2px 10px;
  background: var(--secondary-color);
  color: #fff;
  margin: 0;
  border: none;
  font-size: 1.5rem;
  line-height: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.powered-by {
  background: var(--showcase-bg-color);
  border-top: #a08f6f solid 1px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  z-index: 1000;
  font-size: 0.8rem;
}

/* @media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) 
  .display-1 {
    font-size: 3rem;
    color: #fff;
  }

  .display-2 {
    font-size: 2rem;
  }

  .display-3 {
    font-size: 1.5rem;
    color: #fff;
  }
  .container {
    width: 95%;
  }
  .showcase {
    width: 100%;
    height: 100vh;
    position: relative;
    background: url('/img/luisaldrey_train1.jpg') no-repeat 75% 15%;
    z-index: 1000;
    background-size: cover;
  }
  .showcase-content {
    padding: 0;
    padding-top: 13rem;
  }
  .showcase img {
    width: 20%;
    z-index: 1000;
  }
  .col-6 {
    width: 50%;
  }
  .showcase-top img {
    width: 100%;
    padding: 10px;
    z-index: 1000;
  }
  .showcase-content p {
    padding: 2rem 0;
    font-size: 1.2rem;
  }
  .showcase-content p a {
    text-align: center;
  }
}
@media only screen and (device-width: 768px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) 
  .display-1 {
    font-size: 3rem;
    color: #fff;
  }

  .display-2 {
    font-size: 2rem;
  }

  .display-3 {
    font-size: 1.5rem;
    color: #fff;
  }
  .container {
    width: 95%;
  }
  .showcase {
    width: 100%;
    height: 100vh;
    position: relative;
    background: url('/img/luisaldrey_train1.jpg') no-repeat 75% 15%;
    z-index: 1000;
    background-size: cover;
  }
  .showcase-content {
    padding: 0;
    padding-top: 13rem;
  }
  .showcase img {
    width: 20%;
    z-index: 1000;
  }
  .col-6 {
    width: 100%;
  }
  .showcase-top img {
    width: 60%;
    padding: 10px;
    z-index: 1000;
  }
  .showcase-content p {
    padding: 2rem 0;
    font-size: 1.2rem;
  }
  .showcase-content p a {
    text-align: center;
  }
} */
/* different techniques for iPad screening 
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only 
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only 
}
*/

@media (max-width: 1333px) {
  .grid-item {
    flex-basis: 33.33%;
  }
}

@media (max-width: 1073px) {
  .grid-item {
    flex-basis: 33.33%;
  }
}

@media (max-width: 815px) {
  .grid-item {
    flex-basis: 50%;
  }
}

@media (max-width: 555px) {
  .grid-item {
    flex-basis: 100%;
  }
  .fan-wall {
    height: 100% !important;
  }
  .fan-wall .container {
    display: grid;
    grid-template-columns: 1fr;
    /* margin: 0 auto 3% auto; */
  }
  .fan-wall .fan-pic {
    margin: 3% auto 3% auto;
  }
  .fan-wall img {
    border-radius: 50%;
    width: 180px;
    height: 180px;
  }
  .fan-wall .fan-comment p {
    padding: 0;
  }

  .fan-wall .fan-pic {
    display: flex;
    /* grid-template-columns: 1fr; */
    /* max-width: 1100px; */
    /* margin: 0 auto 3% auto; */
  }

  footer .container {
    display: grid;
    grid-template-columns: 1fr;
  }
  footer .about-footer {
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* padding: 3% 3% 0 3%; */
    /* padding-bottom: 0; */
  }

  footer .site-map-footer ul {
    padding: 3%;
  }

  footer .contact-info .email {
    padding: 3%;
  }

  footer .contact-info a {
    padding: 3% 3% 7% 3%;
  }

  footer .subscribe {
    width: 100%;
    margin: auto;
    padding-top: 5%;
  }
}
